import React from 'react';

const ListTexture = (({ textureListStyle, currentTexture, onChangeTexture }) =>
  <ul className={`children-attributes ${textureListStyle}`}>
    {
      currentTexture.map((image, index) => {
        return (
          <li key={index} onClick={() => onChangeTexture(index)}>
            <span className="color-main_previews">{image.color}</span>
            <img key={index} src={image.preview} alt={image} />
            <div className="main-tooltip-roofs tooltip-textures how-to-start">Выберите цвет текстур</div>
          </li>
          )
      })
    }
  </ul>
);

export default ListTexture;