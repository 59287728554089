/*jshint esversion: 6 */
import React, { Component } from "react";

export default class Calculator extends Component {
  render() {
    return (
      <>
        <h2 className="main-title">Введите площадь мощения</h2>
        <form className="form-calculate">
          <ul className="listCalculate">
            <li className="wrap-input">
              <input
                type="number"
                value={this.props.area}
                onChange={e => this.props.setArea(e)}
                placeholder="Площадь (м2)"
                name="area"
                className="calculationField"
                id="area"
              />
            </li>
            <li>
              <span className="plaza-area">или ширину и длину</span>
            </li>
            <li className="wrap-input">
              <input
                type="number"
                value={this.props.width}
                onChange={e => this.props.onChangeCalculationValue(e)}
                placeholder="Ширина (м)"
                name="width"
                className="calculationField"
                id="width"
              />
            </li>
            <li className="wrap-input">
              <input
                type="number"
                value={this.props.height}
                onChange={e => this.props.onChangeCalculationValue(e)}
                placeholder="Длина (м)"
                name="height"
                className="calculationField"
                id="mainLength"
              />
            </li>
          </ul>
        </form>
        <div className="how-to-start paving-area">Введите площадь мощения</div>
      </>
    );
  }
}
