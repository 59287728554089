import React from "react";

export default class EmailModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  render() {
    return (
      <div className="form-popup">
        <form className="form-container">
          <h2>Письмо успешно отправлено!</h2>
          <button
            type="button"
            onClick={this.props.toggleModal}
            className="btn cancel"
          >
            <i className="fas fa-times"></i>
          </button>
        </form>
      </div>
    );
  }
}
