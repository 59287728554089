import React from 'react';

const TypeTales = ({ tales, onChangeTypeTale }) => (
	<ul className="common-list-options list-tales">
		{' '}
		{tales.map((tale, index) => {
			return (
				<li key={index} onClick={(event) => onChangeTypeTale(event, index)}>
					<span> Форма: {tale.name} </span>{' '}
					<img className="current-tale" src={tale.preview} alt={tale.preview} />{' '}
				</li>
			);
		})}{' '}
	</ul>
);

export default TypeTales;
