import React from 'react';

const ListRoofs = (({ roofListStyle, currentHouse, onChangeRoof }) =>

  <ul className={`children-attributes ${roofListStyle}`}>
    {
      currentHouse.roofs.map((roof, index) => {
        return (
          <li key={index} onClick={() => onChangeRoof(index)}>
            <span className="color-main_previews">{roof.color}</span>
            <img src={roof.preview} alt={roof.preview} />
            <div className="main-tooltip-roofs how-to-start">Выберите цвет крыши</div>
          </li>
        )
      })
    }
  </ul>
);

export default ListRoofs;