/*jshint esversion: 6 */
import React, { Component } from "react";
import FeedbackForm from "./FeedbackForm";

export default class CalculationResult extends Component {
  quantityPallets() {
    if (this.props.area && this.props.currentTaleType) {
      return Math.round(
        this.props.area / this.props.currentTaleType.one_pallet
      );
    }
  }
  weightPallets() {
    let quantityPallets = this.quantityPallets();
    if (quantityPallets && this.props.currentTaleType) {
      return quantityPallets * this.props.currentTaleType.weight_pallet;
    }
  }
  render() {
    return (
      <>
        <h2 className="main-title">Результат расчётов</h2>
        <ul className="list-result_calc">
          <li>
            <span className="color-till_caption">Площадь: </span>
            <span className="color-till">{this.props.area}</span>
            <span className="color-till_caption"> м2</span>
          </li>
          <li>
            <span className="color-till_caption">К-во поддонов: </span>
            <span className="color-till">{this.quantityPallets()}</span>
            <span className="color-till_caption"> шт</span>
          </li>
          <li>
            <span className="color-till_caption">Общая масса: </span>
            <span className="color-till">{this.weightPallets()}</span>
            <span className="color-till_caption"> кг</span>
          </li>
          <li>
            <span className="color-till_caption">Мин. отгрузка: </span>
            <span className="color-till_caption">1 поддон</span>
          </li>
        </ul>

        <div className="how-to-start counting-result-tooltip">
          Результаты расчётов
        </div>
        <FeedbackForm
          area={this.props.area}
          currentTaleType={this.props.currentTaleType}
          taleNumber={this.props.taleNumber}
        />
      </>
    );
  }
}
