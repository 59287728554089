/*jshint esversion: 6 */
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { env } from './config';

ReactDOM.render(<App env={env}/>, document.getElementById('root'));

serviceWorker.unregister();
