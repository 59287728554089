import bauren_preview_1 from "../images/preview_textures/bauren/preview_menu/preview_1.jpg";
import bauren_preview_2 from "../images/preview_textures/bauren/preview_menu/preview_2.jpg";
import bauren_preview_3 from "../images/preview_textures/bauren/preview_menu/preview_3.jpg";
import bauren_preview_4 from "../images/preview_textures/bauren/preview_menu/preview_4.jpg";
import bauren_preview_5 from "../images/preview_textures/bauren/preview_menu/preview_5.jpg";
import bauren_preview_6 from "../images/preview_textures/bauren/preview_menu/preview_6.jpg";

import bauren_image_1 from "../images/preview_textures/bauren/textures/texture_1.png";
import bauren_image_2 from "../images/preview_textures/bauren/textures/texture_2.png";
import bauren_image_3 from "../images/preview_textures/bauren/textures/texture_3.png";
import bauren_image_4 from "../images/preview_textures/bauren/textures/texture_4.png";
import bauren_image_5 from "../images/preview_textures/bauren/textures/texture_5.png";
import bauren_image_6 from "../images/preview_textures/bauren/textures/texture_6.png";

import old_tower_preview_1 from "../images/preview_textures/old_tower/preview/old_tower_ferro.jpg";
//import old_tower_preview_2 from "../images/preview_textures/old_tower/preview/old_tower_terrakota.jpg";
import old_tower_preview_3 from "../images/preview_textures/old_tower/preview/old_tower_travertin.jpg";

import old_tower_image_1 from "../images/preview_textures/old_tower/textures/old_tower_texture_ferro.png";
//import old_tower_image_2 from "../images/preview_textures/old_tower/textures/old_tower_texture_terrakota.png";
import old_tower_image_3 from "../images/preview_textures/old_tower/textures/old_tower_texture_travertin.png";

import parquet_preview_1 from "../images/preview_textures/parquet/preview/parquet_klinker.jpg";
import parquet_preview_2 from "../images/preview_textures/parquet/preview/parquet_tiger.jpg";
import parquet_preview_3 from "../images/preview_textures/parquet/preview/parquet_kliff.jpg";
import parquet_preview_4 from "../images/preview_textures/parquet/preview/parquet_ember.jpg";

import parquet_image_1 from "../images/preview_textures/parquet/textures/parquet_texture_klinker.png";
import parquet_image_2 from "../images/preview_textures/parquet/textures/parquet_texture_tiger.png";
import parquet_image_3 from "../images/preview_textures/parquet/textures/parquet_texture_kliff.png";
import parquet_image_4 from "../images/preview_textures/parquet/textures/parquet_texture_ember.png";

import new_sity_preview_1 from "../images/preview_textures/new_sity/preview/new_city_black.jpg";
import new_sity_preview_2 from "../images/preview_textures/new_sity/preview/new_city_bland.jpg";
import new_sity_preview_3 from "../images/preview_textures/new_sity/preview/new_city_chrome.jpg";
import new_sity_preview_4 from "../images/preview_textures/new_sity/preview/new_city_silver.jpg";
import new_sity_preview_5 from "../images/preview_textures/new_sity/preview/new_city_bronz.jpg";
import new_sity_preview_6 from "../images/preview_textures/new_sity/preview/new_city_kliff.jpg";
import new_sity_preview_7 from "../images/preview_textures/new_sity/preview/new_city_bianco-nero.jpg";
import new_sity_preview_8 from "../images/preview_textures/new_sity/preview/new_city_caramello.jpg";

import new_sity_image_1 from "../images/preview_textures/new_sity/textures/new_city_texture_black.png";
import new_sity_image_2 from "../images/preview_textures/new_sity/textures/new_city_texture_bland.png";
import new_sity_image_3 from "../images/preview_textures/new_sity/textures/new_city_texture_chrom.png";
import new_sity_image_4 from "../images/preview_textures/new_sity/textures/new_city_texture_silver.png";
import new_sity_image_5 from "../images/preview_textures/new_sity/textures/new_city_texture_bronz.png";
import new_sity_image_6 from "../images/preview_textures/new_sity/textures/new-city_texture_kliff.png";
import new_sity_image_7 from "../images/preview_textures/new_sity/textures/new-city_texture_bianco-nero.png";
import new_sity_image_8 from "../images/preview_textures/new_sity/textures/new-city_texture_caramello.png";

import classic_preview_1 from "../images/preview_textures/classic/preview/classic_bland.jpg";
import classic_preview_2 from "../images/preview_textures/classic/preview/classic_brown.jpg";
import classic_preview_3 from "../images/preview_textures/classic/preview/classic_buige.jpg";
import classic_preview_4 from "../images/preview_textures/classic/preview/classic_dark_brown.jpg";
import classic_preview_5 from "../images/preview_textures/classic/preview/classic_gray.jpg";
//import classic_preview_6 from "../images/preview_textures/classic/preview/classic_shtain_brown.jpg";
//import classic_preview_7 from "../images/preview_textures/classic/preview/classic_silver.jpg";
import classic_preview_8 from "../images/preview_textures/classic/preview/classic_yellow.jpg";

import classic_image_1 from "../images/preview_textures/classic/textures/classic_texture_bland.png";
import classic_image_2 from "../images/preview_textures/classic/textures/classic_texture_brown.png";
import classic_image_3 from "../images/preview_textures/classic/textures/classic_texture_buige.png";
import classic_image_4 from "../images/preview_textures/classic/textures/classic_texture-dark_brown.png";
import classic_image_5 from "../images/preview_textures/classic/textures/classic_texture_gray.png";
//import classic_image_6 from "../images/preview_textures/classic/textures/classic_texture_shtain_brown.png";
//import classic_image_7 from "../images/preview_textures/classic/textures/classic_texture_silver.png";
import classic_image_8 from "../images/preview_textures/classic/textures/classic_texture_yellow.png";

//import classic_arko_preview_1 from "../images/preview_textures/classic_arko/preview/classic_arko_dark_brown.jpg";

//import classic_arko_image_1 from "../images/preview_textures/classic_arko/texture/classic_arko_dark_brown.png";

//import square_preview_300_1 from "../images/preview_textures/square_300-300/preview/square_300-300_black.jpg";

//import square_image_300_1 from "../images/preview_textures/square_300-300/textures/square_300-300_texture_gray.png";

//import square_preview_100_1 from "../images/preview_textures/square_100-100/preview/saquare_100-100_gray.jpg";
//import square_preview_100_2 from "../images/preview_textures/square_100-100/preview/square_100-100_black.jpg";
//import square_preview_100_3 from "../images/preview_textures/square_100-100/preview/square_100-100_dark_brown.jpg";
//import square_preview_100_4 from "../images/preview_textures/square_100-100/preview/square_100-100_white.jpg";

//import square_image_100_1 from "../images/preview_textures/square_100-100/textures/square_100-100_texture_gray.png";
//import square_image_100_2 from "../images/preview_textures/square_100-100/textures/square_100-100_texture_black.png";
//import square_image_100_3 from "../images/preview_textures/square_100-100/textures/square_100-100_texture_dark_brown.png";
//import square_image_100_4 from "../images/preview_textures/square_100-100/textures/square_100-100_textures_white.png";

import granito_preview_1 from "../images/preview_textures/granite/preview/granite_bland.jpg";
import granito_preview_2 from "../images/preview_textures/granite/preview/granite_bronz.jpg";
//import granito_preview_3 from "../images/preview_textures/granite/preview/granite_safari.jpg";
import granito_preview_3 from "../images/preview_textures/granite/preview/granite_ombra.jpg";

import granito_image_1 from "../images/preview_textures/granite/textures/granite_texture_bland.png";  
import granito_image_2 from "../images/preview_textures/granite/textures/granite_texture_bronz.png";
//import granito_image_3 from "../images/preview_textures/granite/textures/granite_texture_safari.png";
import granito_image_3 from "../images/preview_textures/granite/textures/granite_texture_ombra.png";

import sett_preview_60_1 from "../images/preview_textures/sett_200-100-40/preview/sett_200-100-40_beige.jpg";
import sett_preview_60_2 from "../images/preview_textures/sett_200-100-40/preview/sett_200-100-40_black.jpg";
import sett_preview_60_3 from "../images/preview_textures/sett_200-100-40/preview/sett_200-100-40_brown.jpg";
import sett_preview_60_4 from "../images/preview_textures/sett_200-100-40/preview/sett_200-100-40_dark_brown.jpg";
import sett_preview_60_5 from "../images/preview_textures/sett_200-100-40/preview/sett_200-100-40_dark_red.jpg";
import sett_preview_60_6 from "../images/preview_textures/sett_200-100-40/preview/sett_200-100-40_grey.jpg";
import sett_preview_60_7 from "../images/preview_textures/sett_200-100-40/preview/sett_200-100-40_yellow.jpg";

import sett_image_60_1 from "../images/preview_textures/sett_200-100-40/textures/sett_200-100-40_texture_buige.png";
import sett_image_60_2 from "../images/preview_textures/sett_200-100-40/textures/sett_200-100-40_texture_black.png";
import sett_image_60_3 from "../images/preview_textures/sett_200-100-40/textures/sett_200-100-40_texture_brown.png";
import sett_image_60_4 from "../images/preview_textures/sett_200-100-40/textures/sett_200-100-40_texture_dark_brown.png";
import sett_image_60_5 from "../images/preview_textures/sett_200-100-40/textures/sett_200-100-40_texture_dark_red.png";
import sett_image_60_6 from "../images/preview_textures/sett_200-100-40/textures/sett_200-100-40_texture_gray.png";
import sett_image_60_7 from "../images/preview_textures/sett_200-100-40/textures/sett_200-100-40_texture_yellow.png";

//import sett_preview_80_1 from "../images/preview_textures/sett_200-100-80/preview/sett_200-100-80_black.jpg";
//import sett_preview_80_2 from "../images/preview_textures/sett_200-100-80/preview/sett_200-100-80_brown.jpg";
//import sett_preview_80_3 from "../images/preview_textures/sett_200-100-80/preview/sett_200-100-80_buige.jpg";
//import sett_preview_80_4 from "../images/preview_textures/sett_200-100-80/preview/sett_200-100-80_dark_brown.jpg";
//import sett_preview_80_5 from "../images/preview_textures/sett_200-100-80/preview/sett_200-100-80_dark_red.jpg";
//import sett_preview_80_6 from "../images/preview_textures/sett_200-100-80/preview/sett_200-100-80_gray.jpg";
//import sett_preview_80_7 from "../images/preview_textures/sett_200-100-80/preview/sett_200-100-80_yellow.jpg";

//import sett_image_80_1 from "../images/preview_textures/sett_200-100-80/textures/sett_200-100-80_texture_black.png";
//import sett_image_80_2 from "../images/preview_textures/sett_200-100-80/textures/sett_200-100-80_texture_brown.png";
//import sett_image_80_3 from "../images/preview_textures/sett_200-100-80/textures/sett_200-100-80_texture_buige.png";
//import sett_image_80_4 from "../images/preview_textures/sett_200-100-80/textures/sett_200-100-80_texture_dark_brown.png";
//import sett_image_80_5 from "../images/preview_textures/sett_200-100-80/textures/sett_200-100-80_dark_red.png";
//import sett_image_80_6 from "../images/preview_textures/sett_200-100-80/textures/sett_200-100-80_textures_gray.png";
//import sett_image_80_7 from "../images/preview_textures/sett_200-100-80/textures/sett_200-100-80_texture_yellow.png";

import preview_tales_nav_bauren from "../images/Prevuy_plitki/bavariya.jpg";
import preview_tales_nav_old_tower from "../images/Prevuy_plitki/Stariy_gorod.jpg";
import preview_tales_nav_parquet from "../images/Prevuy_plitki/Parket.jpg";
import preview_tales_new_city from "../images/Prevuy_plitki/Noviy_gorod.jpg";
import preview_tales_classic from "../images/Prevuy_plitki/Klassika.jpg";
//import preview_tales_classic_arko from "../images/Prevuy_plitki/Klassika_arco.jpg";
//import preview_tales_square_300 from "../images/Prevuy_plitki/Kvadrat_300-300-50.jpg";
//import preview_tales_square_100 from "../images/Prevuy_plitki/Kvadrat_100-100-60.jpg";
import preview_tales_granito from "../images/Prevuy_plitki/Granito.jpg";
import preview_tales_sett_60 from "../images/Prevuy_plitki/Bruschatka_200-100-60.jpg";
//import preview_tales_sett_80 from "../images/Prevuy_plitki/Bruschatka_200-100-80.jpg";

export const tales = [
  {
    preview: bauren_preview_1,
    previewNav: preview_tales_nav_bauren,
    name: "Бавария",
    one_pallet: 12.672,
    weight_pallet: 1670,
    images: [bauren_image_1, bauren_image_2, bauren_image_3, bauren_image_4, bauren_image_5, bauren_image_6],
    previews: [
      {
        url: bauren_preview_1,
        color: "Штайн Браун"
      },
      {
        url: bauren_preview_2,
        color: "Штайн Ферро"
      },
      {
        url: bauren_preview_3,
        color: "Травертин"
      },
      {
        url: bauren_preview_4,
        color: "Терракота"
      },
      {
        url: bauren_preview_5,
        color: "Bianco Nero"
      },
      {
        url: bauren_preview_6,
        color: "Caramello"
      }
    ]
  },
  {
    preview: old_tower_preview_1,
    name: "Старый город",
    previewNav: preview_tales_nav_old_tower,
    one_pallet: 13.596,
    weight_pallet: 1880,
    images: [old_tower_image_1, old_tower_image_3],
    previews: [
      {
        url: old_tower_preview_1,
        color: "Штайн Ферро"
      },
///      {
//       url: old_tower_preview_2,
//        color: "Терракота"
//      },
      {
        url: old_tower_preview_3,
        color: "Травертин"
      }
    ]
  },
  {
    preview: parquet_preview_1,
    name: "Паркет",
    previewNav: preview_tales_nav_parquet,
    one_pallet: 12.672,
    weight_pallet: 1670,
    images: [parquet_image_1, parquet_image_2, parquet_image_3, parquet_image_4],
    previews: [
      {
        url: parquet_preview_1,
        color: "Клинкер"
      },
      {
        url: parquet_preview_2,
        color: "Тигр"
      },
      {
        url: parquet_preview_3,
        color: "Клифф"
      },
      {
        url: parquet_preview_4,
        color: "Эмбер"
      }
    ]
  },
  {
    preview: new_sity_preview_1,
    name: "Новый город",
    previewNav: preview_tales_new_city,
    one_pallet: 13.824,
    weight_pallet: 1910,
    images: [
      new_sity_image_1,
      new_sity_image_2,
      new_sity_image_3,
      new_sity_image_4,
      new_sity_image_5,
      new_sity_image_6,
      new_sity_image_7,
      new_sity_image_8
    ],
    previews: [
      {
        url: new_sity_preview_1,
        color: "Штайн Блэк"
      },
      {
        url: new_sity_preview_2,
        color: "Бленд"
      },
      {
        url: new_sity_preview_3,
        color: "Штайн Хром"
      },
      {
        url: new_sity_preview_4,
        color: "Штайн Сильвер"
      },
      {
        url: new_sity_preview_5,
        color: "Штайн Бронз"
      },
      {
        url: new_sity_preview_6,
        color: "Клифф"
      },
      {
        url: new_sity_preview_7,
        color: "Bianco Nero"
      },
      {
        url: new_sity_preview_8,
        color: "Caramello"
      }
    ]
  },
  {
    preview: classic_preview_1,
    name: "Классика",
    previewNav: preview_tales_classic,
    one_pallet: 12.672,
    weight_pallet: 1660,
    images: [
      classic_image_1,
      classic_image_2,
      classic_image_3,
      classic_image_4,
      classic_image_5,
      classic_image_8
    ],
    previews: [
      {
        url: classic_preview_1,
        color: "Блэнд"
      },
      {
        url: classic_preview_2,
        color: "Коричневый"
      },
      {
        url: classic_preview_3,
        color: "Бежевый"
      },
      {
        url: classic_preview_4,
        color: "Темно-коричневый"
      },
      {
        url: classic_preview_5,
        color: "Серый"
      },
//      {
//        url: classic_preview_6,
//        color: "Штайн Браун"
//     },
//      {
//        url: classic_preview_7,
//        color: "Штайн Сильвер"
//      },
      {
        url: classic_preview_8,
        color: "Желтый"
      }
    ]
  },
//  {
//    preview: classic_arko_preview_1,
//    name: "Классика Арко",
//    previewNav: preview_tales_classic_arko,
//    one_pallet: 12.096,
//    weight_pallet: 1700,
//    images: [classic_arko_image_1],
//    previews: [
//      {
//        url: classic_arko_preview_1,
//        color: "Темно-коричневый"
//      }
//    ]
//  },
//  {
//    preview: square_preview_300_1,
//    name: "Квадрат 300х300х50",
//    previewNav: preview_tales_square_300,
//    one_pallet: 12.96,
//    weight_pallet: 1470,
//    images: [square_image_300_1],
//    previews: [
//      {
//        url: square_preview_300_1,
//        color: "Серый"
//      }
//    ]
//  },
//  {
//    preview: square_preview_100_1,
//    name: "Квадрат 100х100х60",
//    previewNav: preview_tales_square_100,
//    one_pallet: 11.88,
//    weight_pallet: 1540,
//    images: [
//      square_image_100_1,
//      square_image_100_2,
//      square_image_100_3,
//      square_image_100_4
//    ],
//    previews: [
//      {
//        url: square_preview_100_1,
//        color: "Серый"
//      },
//      {
//        url: square_preview_100_2,
//        color: "Чёрный"
//      },
//      {
//        url: square_preview_100_3,
//        color: "Темно-коричневый"
//      },
//      {
//        url: square_preview_100_4,
//        color: "Белый"
//      }
//    ]
//  },
  {
    preview: granito_preview_1,
    name: "Гранито",
    previewNav: preview_tales_granito,
    one_pallet: 13.368,
    weight_pallet: 1820,
    images: [granito_image_1, granito_image_2, granito_image_3],
    previews: [
      {
        url: granito_preview_1,
        color: "Бленд"
      },
      {
        url: granito_preview_2,
        color: "Штайн Бронз"
      },      
      {
        url: granito_preview_3,
        color: "Ombra"
      },
//      {
//        url: granito_preview_3,
//        color: "Сафари"
//      }
    ]
  },
  {
    preview: sett_preview_60_1,
    name: "Брусчатка 200х100х60",
    previewNav: preview_tales_sett_60,
    one_pallet: 12.96,
    weight_pallet: 1680,
    images: [
      sett_image_60_1,
      sett_image_60_2,
      sett_image_60_3,
      sett_image_60_4,
      sett_image_60_5,
      sett_image_60_6,
      sett_image_60_7
    ],
    previews: [
      {
        url: sett_preview_60_1,
        color: "Бежевый"
      },
      {
        url: sett_preview_60_2,
        color: "Чёрный"
      },
      {
        url: sett_preview_60_3,
        color: "Коричневый"
      },
      {
        url: sett_preview_60_4,
        color: "Темно-коричневый"
      },
      {
        url: sett_preview_60_5,
        color: "Темно-красный"
      },
      {
        url: sett_preview_60_6,
        color: "Серый"
      },
      {
        url: sett_preview_60_7,
        color: "Жёлтый"
      }
    ]
  },
//  {
//    preview: sett_preview_80_1,
//    name: "Брусчатка 200х100х80",
//    previewNav: preview_tales_sett_80,
//    one_pallet: 10.8,
//    weight_pallet: 1980,
//    images: [
//      sett_image_80_1,
//      sett_image_80_2,
//      sett_image_80_3,
//      sett_image_80_4,
//      sett_image_80_5,
//      sett_image_80_6,
//      sett_image_80_7
//    ],
//    previews: [
//      {
//        url: sett_preview_80_1,
//        color: "Чёрный"
//      },
//      {
//        url: sett_preview_80_2,
//        color: "Коричневый"
//      },
//      {
//        url: sett_preview_80_3,
//        color: "Бежевый"
//      },
//      {
//        url: sett_preview_80_4,
//        color: "Темно-коричневый"
//      },
//      {
//        url: sett_preview_80_5,
//        color: "Темно-красный"
//      },
//      {
//        url: sett_preview_80_6,
//        color: "Серый"
//      },
//      {
//        url: sett_preview_80_7,
//        color: "Желтый"
//      }
//    ]
//  }
];

export default tales;
