import React from "react";

const TypeTales = ({ currentTaleType, onChangeTales }) => {
  return (
    <ul className="children-attributes">
      {currentTaleType.previews.map((tale, index) => {
        return (
          <li onClick={() => onChangeTales(index)} key={index}>
            <span className="preview-tale_color color-main_previews">
               {tale.color}
            </span>
            <img className="current-tale 1" src={tale.url} alt={tale.url} />
            <div className="main-tooltip-roofs how-to-start">
              Выберите цвет плитки
            </div>
          </li>
        );
      })}
    </ul>
  );
};

export default TypeTales;
