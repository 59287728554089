/*jshint esversion: 6 */
import React, {Component} from 'react';
import Roof from './Roof';
import ListTexture from './ListTexture';
import Texture from './Texture';
import Houses from './Houses';
import ListTypeTextures from './ListTypeTextures';
import TypeTales from './TypeTales';
import ListTales from './ListTales';
import ListRoofs from './ListRoofs';

export default class House extends Component {

  render() {
    const {
      textureNumber,
      textureListStyle,
      textureTypeNumber,
      roofNumber,
      currentHouse,
      toggleHouses,
      houses,
      onChangeHouse,
      tales,
      onChangeTypeTale,
      toggleTypeTales,
      toggleTales,
      onChangeTales,
      currentTaleType,
      toggleRoofs,
      roofListStyle,
      onChangeRoof,
      toggleTypeTextures,
      onChangeTypeTexture,
      onChangeTexture,
      currentTexture,
      toggleTextures,
    } = this.props;


    return (
      <div className="wrap-main_preview">
        <ul className="wrap-main_image">
          <li className="item-main_background">
            <img className="main-background" src="./images/uchastok_bez_doma.jpg" alt="" />
          </li>
          <li>
                <img className="main-image" src={currentHouse.url} alt={currentHouse.url} />
          </li>
          <li>
            { roofNumber !== 'undefined' && typeof roofNumber == "number"
              ?
              <Roof currentRoof={currentHouse.roofs[roofNumber]} />
              :
              null
            }
          </li>
          <li>
            { textureNumber !== 'undefined' && typeof textureNumber == "number"
              ?
              <Texture textureNumber={textureNumber} currentTexture={currentHouse.textures[textureTypeNumber]} />
              :
              null
            }
          </li>
        </ul>
          {
            toggleHouses && <Houses houses={houses}
                                    onChangeHouse={onChangeHouse} />
          }
          {
            toggleTales && <ListTales currentTaleType={currentTaleType} onChangeTales={onChangeTales} />
          }
          {
            toggleTypeTales && <TypeTales tales={tales} onChangeTypeTale={onChangeTypeTale} />
          }
          {
            toggleRoofs && <ListRoofs roofListStyle={roofListStyle}
                                      onChangeRoof={onChangeRoof}
                                      currentHouse={currentHouse}
                                      />
          }
          {
            toggleTypeTextures && <ListTypeTextures onChangeTypeTexture={onChangeTypeTexture}
                                                currentTexture={currentTexture}
                                                currentHouse={currentHouse}
                                              />
          }
          { toggleTextures && <ListTexture textureListStyle={textureListStyle} onChangeTexture={onChangeTexture} currentTexture={currentHouse.textures[textureTypeNumber].previews} />}
      </div>
    )
  }
}
