/*jshint esversion: 6 */
import React, { createElement } from 'react';
import axios from 'axios';
import ResultTypeTale from './ResultTypeTale';

const MANAGER_EMAIL = 'steingot.mail@gmail.com';
// Need to set manager email

export default class EmailModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      phone: '',
    };
  }

  quantityPallets() {
    if (this.props.area && this.props.currentTaleType) {
      return Math.round(this.props.area / this.props.currentTaleType.one_pallet);
    }
  }
  weightPallets() {
    let quantityPallets = this.quantityPallets();
    if (quantityPallets && this.props.currentTaleType) {
      return quantityPallets * this.props.currentTaleType.weight_pallet;
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  closeForm = () => {};

  createCanvas = () => {
    let canvas = document.createElement('canvas');
    canvas.width = 1225;
    canvas.height = 689;
    let canvasContext = canvas.getContext('2d');
    let getElemHouse = [document.querySelector('.main-background')];
    getElemHouse.push(...document.querySelectorAll('.main-container img:not(.main-background)'));
    for (let i = 0; i < getElemHouse.length; i++) {
      let el = getElemHouse[i];
      if (el.className === '' || el.className === 'img-responsive' || /current-tale/.test(el.className)) {
        break;
      }
      let img = document.createElement('img');
      img.src = el.src;
      canvasContext.drawImage(img, 0, 0, 1225, 689);
    }
    return canvas.toDataURL();
  };

  handleSubmit = async (e) => {
    let newImage = this.createCanvas();
    e.preventDefault();
    const emails = [{ email: MANAGER_EMAIL }, { email: this.state.email }];
    emails.forEach((emailData) => {
      axios
        .post('https://mail-steingot.herokuapp.com/api/contact', {
          area: this.props.area,
          email: emailData.email,
          phone: this.state.phone,
          quantityPallets: this.quantityPallets(),
          weightPallets: this.weightPallets(),
          color: this.props.currentTaleType.previews[this.props.taleNumber].color,
          one_pallet: this.props.currentTaleType.one_pallet,
          weight_pallet: this.props.currentTaleType.weight_pallet,
          name: this.props.currentTaleType.name,
          user_email: emails[1].email,
          imageHomeBase64: newImage,
        })
        .then((res) => {
          alert('Письмо успешно отправлено');
        })
        .catch((err) => {
          console.log(err);
        });
    });
    this.props.showSuccessCb();
  };

  render() {
    return (
      <div className="form-popup">
        <form className="form-container" onSubmit={this.handleSubmit}>
          <h1>Результаты выборки</h1>
          <div className="form-popup__wrapper">
            <ResultTypeTale currentTaleType={this.props.currentTaleType} taleNumber={this.props.taleNumber} />
            <div className="form-popup__wrapper-calculation">
              <h2 className="main-title">Результат расчётов</h2>
              <ul className="list-result_calc">
                <li>
                  <span className="color-till_caption">Площадь: </span>
                  <span className="color-till">{this.props.area}</span>
                  <span className="color-till_caption"> м2</span>
                </li>
                <li>
                  <span className="color-till_caption">К-во поддонов: </span>
                  <span className="color-till">{this.quantityPallets()}</span>
                  <span className="color-till_caption"> шт</span>
                </li>
                <li>
                  <span className="color-till_caption">Общая масса: </span>
                  <span className="color-till">{this.weightPallets()}</span>
                  <span className="color-till_caption"> кг</span>
                </li>
                <li>
                  <span className="color-till_caption">Мин. отгрузка: </span>
                  <span className="color-till_caption">1 поддон</span>
                </li>
              </ul>
            </div>
            <div className="form-popup__wrapper-input">
              {this.props.isClickBtn === 'manager' ? (
                <>
                  <input
                    onChange={this.handleChange}
                    type="text"
                    value={this.state.email}
                    placeholder="Введите свой email"
                    name="email"
                    required
                  />
                  <input
                    onChange={this.handleChange}
                    type="text"
                    value={this.state.phone}
                    placeholder="Введите номер телефона"
                    name="phone"
                    required
                  />
                </>
              ) : (
                <input
                  onChange={this.handleChange}
                  type="text"
                  value={this.state.email}
                  placeholder="Введите свой email"
                  name="email"
                  required
                />
              )}

              <div className="confirm-block">
                <input type="checkbox" id="data-check" name="data-check" required />
                <label className="confirm-label" htmlFor="data-check">
                  Я соглашаюсь на обработку своих персональных данных
                </label>
              </div>
            </div>
          </div>
          <button type="button" onClick={this.props.toggleModal} className="btn cancel">
            <i className="fas fa-times"></i>
          </button>
          <input type="submit" className="btn" value="Отправить" />
        </form>
      </div>
    );
  }
}
