/*jshint esversion: 6 */
import React from "react";
import EmailModal from "./EmailModal";
import Modal from "../Modal";

export default class FeedbackForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      isInput: ""
    };
    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal(e) {
    this.setState({
      visible: !this.state.visible,
      isInput: e.target.dataset.btn
    });
  }

  closeSuccessModal = () => {
    this.setState({
      isOpenSuccessModal: false
    })
  }

  showSuccessCb = () => {
    this.setState({
      isOpenSuccessModal: true,
      visible: false
    })
  }

  render() {
    const { area } = this.props;
    return (
      <div className="wrap-result-btns">
        {this.state.visible && (
          <EmailModal
            showSuccessCb={this.showSuccessCb}
            taleNumber={this.props.taleNumber}
            area={area}
            toggleModal={this.toggleModal}
            currentTaleType={this.props.currentTaleType}
            isClickBtn={this.state.isInput}
          />
        )}
        {this.state.isOpenSuccessModal && <Modal toggleModal={this.closeSuccessModal} /> }
        <button data-btn="manager" onClick={e => this.toggleModal(e)}>
          Отправить запрос в steingot
        </button>
        <button onClick={e => this.toggleModal(e)}>
          Отправить себе на e-mail
        </button>
      </div>
    );
  }
}
