/*jshint esversion: 6 */
import React, {Component} from 'react';
import ListMenu from './Listmenu';
import Calculator from './Calculator';
import CalculationResult from './CalculationResult';
import ResultTypeTale from './ResultTypeTale';

export default class MenuNavigation extends Component {
  constructor(props){
    super(props);
    this.state = {
      width: '',
      height: '',
      area: ''
    };
  }

  calculateArea = () => {
    this.setState({
      area: this.state.width * this.state.height
    });
  }

  setArea = (event) => {
    this.setState({
      area: event.target.value,
      width: 0,
      height: 0
    });
  }

  changeCalculationValue = (event) =>{
    this.setState({
      [event.target.name]: event.target.value
    }, () => {
      this.calculateArea();
    });
  }

  render() {
    return (
      <ul className="main-list_navigation">
        <li>
          <ListMenu
            toggleHouses={this.props.toggleHouses}
            toggleRoofs={this.props.toggleRoofs}
            toggleTypeTextures={this.props.toggleTypeTextures}
            toggleTextures={this.props.toggleTextures}
            toggleTales={this.props.toggleTales}
            toggleTypeTales={this.props.toggleTypeTales}
            onChangeTales={this.props.onChangeTales}
            onChangeHouse={this.props.onChangeHouse}

            currentHouse={this.props.currentHouse}
            currentTexture={this.props.currentTexture}
            currentTaleType={this.props.currentTaleType}
            taleNumber={this.props.taleNumber}
            textureNumber={this.props.textureNumber}

            houses={this.props.houses}
            tales={this.props.tales}

            onShowItem={this.props.onShowItem}
          />
        </li>
        <li> <Calculator
                calculateArea={this.calculateArea}
                onChangeCalculationValue={this.changeCalculationValue}
                setArea={this.setArea}
                width={this.state.width}
                height={this.state.height}
                area={this.state.area}
              />
        </li>

        <li> <ResultTypeTale
                currentTaleType={this.props.currentTaleType}
                taleNumber={this.props.taleNumber}
             />
        </li>
        <li> <CalculationResult
                env={this.props.env}
                area={this.state.area}
                currentTaleType={this.props.currentTaleType}
                taleNumber={this.props.taleNumber}
              />
        </li>
      </ul>
    )
  }
}
