import React from 'react';

const ListTypeTextures = ({ currentHouse, onChangeTypeTexture }) => (
	<ul className="common-list-options list-textures">
		{' '}
		{currentHouse.textures.map((texture, index) => {
			return (
				<li onClick={(event) => onChangeTypeTexture(event, index)} key={index}>
					<img src={texture.preview} alt={texture.preview} />{' '}
				</li>
			);
		})}{' '}
	</ul>
);

export default ListTypeTextures;
