import React from "react";
import MainPreview from "./MainPreview";
import MenuNavigation from "./Navigation/MenuNavigation";
import Tale from "./Tale";

import houses from "./constants/houses";
import tales from "./constants/tales";

const roofStyles = ["listRoofsTop1", "listRoofsTop2", "listRoofsTop3"];

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      houseNumber: null,
      toggleHouses: false,
      toggleRoofs: false,
      roofNumber: null,

      textureTypeNumber: null,
      textureNumber: null,
      toggleTypeTextures: false,
      toggleTextures: false,

      taleTypeNumber: null,
      taleNumber: null,
      toggleTales: false,
      toggleTypeTales: false
    };
  }

  removeList = () => {
    const list = document.querySelectorAll(".wrap-main_preview ul li");
    list.forEach(element => {
      element.classList.remove("active-item");
    });
  };

  addStylesToList = event => {
    if (!event.target.closest("li").classList.contains("active-item")) {
      this.removeList();
      event.target.closest("li").classList.add("active-item");
    }
  };

  changeHouse = (event, houseNumber = 0) => {
    event.preventDefault();
    this.addStylesToList(event);
    this.setState({
      houseNumber: houseNumber,
      toggleRoofs: true
    });
  };

  changeRoof = roofNumber => {
    this.setState({
      roofNumber,
      toggleRoofs: false,
      toggleHouses: false
    });
  };

  changeFacadeTexture = textureNumber => {
    this.setState({
      textureNumber,
      // textureTypeNumber,
      toggleTextures: false,
      toggleTypeTextures: false
    });
  };

  changeTypeTexture = (event, textureTypeNumber) => {
    this.addStylesToList(event);
    this.setState({
      textureTypeNumber,
      textureNumber: 0,
      toggleTextures: true
    });
  };

  changeTypeTale = (event, taleTypeNumber) => {
    this.addStylesToList(event);
    this.setState({
      taleTypeNumber,
      taleNumber: 0,
      toggleTales: true
    });
  };

  changeTales = taleNumber => {
    this.setState({
      taleNumber,
      toggleTypeTales: false,
      toggleTales: false
    });
  };

  resetLists = () => {
    this.setState({
      toggleHouses: false,
      toggleTextures: false,
      toggleTypeTextures: false,
      toggleTales: false,
      toggleRoofs: false,
      toggleTypeTales: false
    });
  };

  showItem = (itemName, itemColor) => {
    if (this.state.houseNumber === null && itemName === "toggleHouses") {
      this.setState({
        houseNumber: 0,
        roofNumber: 0
      });
    } else if (
      this.state.textureNumber === null &&
      itemName === "toggleTypeTextures"
    ) {
      this.setState({
        textureTypeNumber: 0,
        textureNumber: 0
      });
    } else if (
      this.state.taleNumber === null &&
      itemName === "toggleTypeTales"
    ) {
      this.setState({
        taleNumber: 0,
        taleTypeNumber: 0
      });
    }
    document.querySelector(".test").style.display = "none";
    this.resetLists();
    this.setState({
      [itemName]: !this.state[itemName],
      [itemColor]: !this.state[itemColor]
    });
  };

  render() {
    return (
      <div className="main-container">
        {this.state.taleNumber || this.state.taleNumber === 0 ? (
          <Tale
            currentTale={
              tales[this.state.taleTypeNumber || 0].images[
                this.state.taleNumber || 0
              ]
            }
          />
        ) : null}
        {this.state.houseNumber !== "undefined" ? (
          <MainPreview
            roofNumber={this.state.roofNumber}
            textureTypeNumber={this.state.textureTypeNumber || 0}
            textureNumber={this.state.textureNumber}
            currentHouse={houses[this.state.houseNumber || 0]}
            houses={houses}
            onChangeHouse={this.changeHouse}
            toggleHouses={this.state.toggleHouses}
            currentTaleType={tales[this.state.taleTypeNumber || 0]}
            tales={tales}
            onChangeTypeTale={this.changeTypeTale}
            toggleTypeTales={this.state.toggleTypeTales}
            onChangeTales={this.changeTales}
            toggleTales={this.state.toggleTales}
            toggleRoofs={this.state.toggleRoofs}
            roofListStyle={roofStyles[this.state.houseNumber || 0]}
            textureListStyle={roofStyles[this.state.textureTypeNumber]}
            onChangeRoof={this.changeRoof}
            onChangeTypeTexture={this.changeTypeTexture}
            onChangeTexture={this.changeFacadeTexture}
            toggleTypeTextures={this.state.toggleTypeTextures}
            toggleTextures={this.state.toggleTextures}
            currentTexture={
              houses[this.state.houseNumber || 0].textures[
                this.state.textureTypeNumber
              ]
            }
            onShowItem={this.showItem}
          />
        ) : null}

        <MenuNavigation
          toggleHouses={this.state.toggleHouses}
          toggleRoofs={this.state.toggleRoofs}
          toggleTypeTextures={this.state.toggleTypeTextures}
          toggleTextures={this.state.toggleTextures}
          toggleTales={this.state.toggleTales}
          toggleTypeTales={this.state.toggleTypeTales}
          env={this.props.env}
          onChangeTales={this.changeTales}
          onChangeHouse={this.changeHouse}
          onShowItem={this.showItem}
          currentHouse={houses[this.state.houseNumber]}
          currentTaleType={tales[this.state.taleTypeNumber]}
          taleNumber={this.state.taleNumber}
          textureNumber={this.state.textureNumber}
          currentTexture={
            houses[this.state.houseNumber || 0].textures[
              this.state.textureTypeNumber || 0
            ]
          }
          currentRoof={
            houses[this.state.houseNumber || 0].roofs[this.state.roofNumber]
          }
          tales={tales}
          houses={houses}
        />
      </div>
    );
  }
}
