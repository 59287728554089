/* Houses */
import house_first_level from '../images/render-house/first_house/house/house_first.png';
import house_second_level from '../images/render-house/second_house/house/second_house.png';
import house_broken_roof from '../images/render-house/third_house/house/third_house.png';
/* First House */
import firstHouseBrownRoof from '../images/render-house/first_house/roofs/brown.png';
import firstHouseDarkRedRoof from '../images/render-house/first_house/roofs/dark_red.png';
import firstHouseGreenRoof from '../images/render-house/first_house/roofs/green.png';
import firstHouseRedRoof from '../images/render-house/first_house/roofs/red.png';

import firstHouseTextureBrickBrown from '../images/render-house/first_house/facture_wall/brick_brown.png';
import firstHouseTextureBrickRed from '../images/render-house/first_house/facture_wall/brick_red.png';
import firstHouseTextureBrickSandy from '../images/render-house/first_house/facture_wall/brick_sandy.png';
import firstHouseTextureBrus from '../images/render-house/first_house/facture_wall/brus.png';
import firstHouseTextureSmooth from '../images/render-house/first_house/facture_wall/smooth.png';
/* Second House */
import secondHouseDarkBrownRoof from '../images/render-house/second_house/roofs/dark_brown.png';
import secondHouseDarkRedRoof from '../images/render-house/second_house/roofs/dark_red.png';
import secondHouseGreenRoof from '../images/render-house/second_house/roofs/green.png';
import secondHouseRedRoof from '../images/render-house/second_house/roofs/red.png';

import secondHouseTextureBrickBrown from '../images/render-house/second_house/texture/brick_brown.png';
import secondHouseTextureBrickRed from '../images/render-house/second_house/texture/brick_red.png';
import secondHouseTextureBrickSandy from '../images/render-house/second_house/texture/brick_sandy.png';
import secondHouseTextureTimber from '../images/render-house/second_house/texture/timber.png';
import secondHouseTextureSmooth from '../images/render-house/second_house/texture/smooth.png';
/* Third House */
import thirdHouseDarkBrownRoof from '../images/render-house/third_house/roofs/dark_brown.png';
import thirdHouseDarkRedRoof from '../images/render-house/third_house/roofs/dark_red.png';
import thirdHouseGreenRoof from '../images/render-house/third_house/roofs/green.png';
import thirdHouseRedRoof from '../images/render-house/third_house/roofs/red.png';

import thirdHouseTextureBrickBrown from '../images/render-house/third_house/texture/brick_brown.png';
import thirdHouseTextureBrickRed from '../images/render-house/third_house/texture/brick_red.png';
import thirdHouseTextureBrickSandy from '../images/render-house/third_house/texture/brick_sandy.png';
import thirdHouseTextureTimber from '../images/render-house/third_house/texture/timber.png';
import thirdHouseTextureSmooth from '../images/render-house/third_house/texture/smooth.png';

/* Preview Textures */
import smooth_texture_preview from '../images/render-house/preview_textures/smooth.png';
import brown_brick_texture_preview from '../images/render-house/preview_textures/brown_brick.png';
import red_brick_texture_preview from '../images/render-house/preview_textures/red_brick.png';
import sand_brick_texture_preview from '../images/render-house/preview_textures/sand_brick.png';
import brus_texture_preview from '../images/render-house/preview_textures/brus.png';

/* Preview Houses */

import house_first_level_preview from '../images/render-house/preview_houses_menu/first_house.png';
import house_second_level_preview from '../images/render-house/preview_houses_menu/second_house.png';
import house_third_level_preview from '../images/render-house/preview_houses_menu/third_house.png'

/* Preview roofs */

import first_brown_roof_preview from '../images/render-house/preview_roofs/dark_brown.png';
import second_dark_red_roof_preview from '../images/render-house/preview_roofs/dark_red.png';
import third_green_roof_preview from '../images/render-house/preview_roofs/green.png';
import fourth_red_roof_preview from '../images/render-house/preview_roofs/red.png';

/* Preview Houses */
import first_house_preview from '../images/render-house/preview_houses_menu/first_house.png';
import second_house_preview from '../images/render-house/preview_houses_menu/second_house.png';
import third_house_preview from '../images/render-house/preview_houses_menu/third_house.png';

const houses = [
  {
    url: house_first_level,
    urlPreview: first_house_preview,
    preview: house_first_level_preview,
    roofs: [
      {
        url: firstHouseBrownRoof,
        preview: first_brown_roof_preview,
        color: 'Коричневый'
      },
      {
        url: firstHouseDarkRedRoof,
        preview: second_dark_red_roof_preview,
        color: 'Тёмно-красный'
      },
      {
        url: firstHouseGreenRoof,
        preview: third_green_roof_preview,
        color: 'Зелёный'
      },
      {
        url: firstHouseRedRoof,
        preview: fourth_red_roof_preview,
        color: 'Красный'
      }
    ],
    textures: [
      {
        preview: brown_brick_texture_preview,
        previews: [
          { preview: brown_brick_texture_preview,
            color: 'Коричневый'
          },
          { preview: red_brick_texture_preview,
            color: 'Красный',
          },
          { preview: sand_brick_texture_preview,
            color: 'Песочный'
          }
        ],
        images: [firstHouseTextureBrickBrown, firstHouseTextureBrickRed, firstHouseTextureBrickSandy]
      },
      { preview: brus_texture_preview,
        previews: [
          {
            preview: brus_texture_preview,
            color: "Брус"
          }
         ],
        images: [firstHouseTextureBrus]
      },
      { preview: smooth_texture_preview,
        previews: [
          { preview: smooth_texture_preview,
            color: 'Штукатурка'
          }
        ],
        images: [firstHouseTextureSmooth]
      }
    ],
  },
  {
    url: house_second_level,
    urlPreview: second_house_preview,
    preview: house_second_level_preview,
    roofs: [
      {
        url: secondHouseDarkBrownRoof,
        preview: first_brown_roof_preview,
        color: 'Коричневый'
      },
      { url: secondHouseDarkRedRoof,
        preview: second_dark_red_roof_preview,
        color: 'Тёмно-красный'
      },
      { url: secondHouseGreenRoof,
        preview: third_green_roof_preview,
        color: 'Зелёный'
      },
      { url: secondHouseRedRoof,
        preview: fourth_red_roof_preview,
        color: 'Красный'
      }
    ],
    textures: [
      {
        preview: brown_brick_texture_preview,
        previews: [
          { preview: brown_brick_texture_preview,
            color: "Коричневый"
          },
          { preview: red_brick_texture_preview,
            color: "Красный"
          },
          { preview: sand_brick_texture_preview,
            color: "Песочный"
          }
        ],
        images: [secondHouseTextureBrickBrown, secondHouseTextureBrickRed, secondHouseTextureBrickSandy]
      },
      { preview: brus_texture_preview,
        previews: [
          { preview: brus_texture_preview,
            color: "Брус"
          }
        ],
        images: [secondHouseTextureTimber]
      },
      { preview: smooth_texture_preview,
        previews: [
          { preview: smooth_texture_preview,
            color: "Штукатурка"
          }
        ],
        images: [secondHouseTextureSmooth]
      }
    ]
  },
  {
    url: house_broken_roof,
    urlPreview: third_house_preview,
    preview: house_third_level_preview,
    roofs: [
      { url: thirdHouseDarkBrownRoof,
        preview: first_brown_roof_preview,
        color: 'Коричневый'
      },
      { url: thirdHouseDarkRedRoof,
        preview: second_dark_red_roof_preview,
        color: 'Тёмно-красный',
      },
      { url: thirdHouseGreenRoof,
        preview: third_green_roof_preview,
        color: 'Зелёный'
      },
      { url: thirdHouseRedRoof,
        preview: fourth_red_roof_preview,
        color: 'Красный'
      }
    ],
    textures: [
      { preview: brown_brick_texture_preview,
        previews: [
          { preview: brown_brick_texture_preview,
            color: "Коричневый"
          },
          { preview: red_brick_texture_preview,
            color: "Красный"
          },
          { preview: sand_brick_texture_preview,
            color: "Песочный"
          }
        ],
        images: [thirdHouseTextureBrickBrown, thirdHouseTextureBrickRed, thirdHouseTextureBrickSandy]
      },
      { preview: brus_texture_preview,
        previews: [
          { preview: brus_texture_preview,
            color: "Брус"
          }
        ],
        images: [thirdHouseTextureTimber]
      },
      { preview: smooth_texture_preview,
        previews: [
          { preview: smooth_texture_preview,
            color: "Штукатурка"
          }
        ],
        images: [thirdHouseTextureSmooth]
      }
    ]
  }
];

export default houses;
