/*jshint esversion: 6 */
import React, { Component } from "react";

export default class ResultTypeTale extends Component {
  render() {
    const { currentTaleType, taleNumber } = this.props;
    return (
      <>
        <div className="preview-block">
          <h2 className="main-title">Вы выбрали</h2>
          <div className="image-type_of-tile">
            <img
              className="talePreview"
              src={currentTaleType && currentTaleType.previewNav}
              alt={currentTaleType && currentTaleType.previewNav}
            />
          </div>
          <strong className="caption-type_of-till">
            {currentTaleType && currentTaleType.name}
          </strong>
          <ul className="result-type_tale">
            <li>
              <span className="color-till_caption">Цвет: </span>
              <span className="color-till">
                {currentTaleType && currentTaleType.previews[taleNumber].color}
              </span>
            </li>

            <li>
              <span className="one-till_caption">1 поддон: </span>
              <span className="color-till">
                {currentTaleType && currentTaleType.one_pallet}
              </span>
              <span className="color-till_caption"> м2</span>
            </li>

            <li>
              <span className="color-till_caption">Общая масса: </span>
              <span className="color-till">
                {currentTaleType && currentTaleType.weight_pallet}
              </span>
              <span className="color-till_caption"> кг</span>
            </li>
          </ul>
        </div>
      </>
    );
  }
}
