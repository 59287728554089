/*jshint esversion: 6 */
import React, { Component } from 'react';
import house_svg from '../images/1.jpg';
import facades_svg from '../images/2.jpg';
import tales_svg from '../images/3.jpg';
import main_logo from '../images/logo_bottom.png';

export default class ListMenu extends Component {
	showCurrentHouse() {
		return this.props.currentHouse ? this.props.currentHouse.preview : house_svg;
	}
	showCurrentTexture() {
		const { currentTexture, textureNumber } = this.props;
		return currentTexture && textureNumber !== null ? currentTexture.previews[textureNumber].preview : facades_svg;
	}
	showCurrentTaleType() {
		const { currentTaleType, taleNumber } = this.props;
		return currentTaleType && taleNumber !== null ? currentTaleType.previews[taleNumber].url : tales_svg;
	}

	render() {
		const { onShowItem } = this.props;
		return (
			<section>
				<h2 className="main-title">Визуальный выбор дома, фасада и мощения тротуарной плиткой </h2>{' '}
				<ul className="main-list_nav">
					<li>
						
						<button onClick={() => onShowItem('toggleHouses', 'toggleRoofs')} className="list-item_btn">
							<img src={this.showCurrentHouse()} alt={this.showCurrentHouse()} />{' '}
							<span className="main-label"> Выбор дома </span>{' '}
						</button>{' '}
						<div className="how-to-start test"> Выберите дом чтобы начать </div>{' '}
					</li>{' '}
					<li>
						<button
							onClick={() => onShowItem('toggleTypeTextures', 'toggleTextures')}
							className="list-item_btn">
							<img src={this.showCurrentTexture()} alt={this.showCurrentTexture()} />{' '}
							<span className="main-label"> Выбор фасада </span>{' '}
						</button>{' '}
						<div className="main-tooltip-facade how-to-start">Нажмите чтобы выбрать фасад </div>{' '}
					</li>{' '}
					<li>
						<button onClick={() => onShowItem('toggleTypeTales', 'toggleTales')} className="list-item_btn">
							<img src={this.showCurrentTaleType()} alt={this.showCurrentTaleType()} />{' '}
							<span className="main-label"> Выбор плитки </span>{' '}
						</button>{' '}
						<div className="main-tooltip-tale how-to-start">Нажмите чтобы выбрать плитку </div>{' '}
					</li>{' '}
				</ul>{' '}
				<a href="/" className="main-logo">
					<img src={main_logo} alt={main_logo} />{' '}
				</a>{' '}
			</section>
		);
	}
}