import React from 'react';

const Houses = ({ houses, onChangeHouse }) => (
	<ul className="common-list-options main-list_houses">
		{' '}
		{houses.map((image, index) => {
			return (
				<li onClick={(event) => onChangeHouse(event, index)} key={index}>
					<img src={image.urlPreview} className="img-responsive" alt={image.urlPreview} />{' '}
				</li>
			);
		})}{' '}
	</ul>
);

export default Houses;
